import {
  ActiveElement,
  Chart,
  ChartData,
  ChartEvent,
  PluginOptionsByType,
  ScaleOptionsByType,
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, {CSSProperties} from 'react';

import {DataRange, GraphTimeRange} from 'app/components/AnalyzePolygonChart/types';
import {
  TOOLTIP_TIME_FORMAT,
  formatUnits,
  useLineChart,
} from 'app/components/AnalyzePolygonChart/utils';
import {DataLayerInfo} from 'app/utils/layers';

import {MultiFeatureScatterDataPoint} from '../utils/chartUtils';

/**
 * Component to render a line chart with an arbitrary number of datasets.
 */
const MultiFeatureAnlaysisLineChart: React.FunctionComponent<{
  layerInfo: DataLayerInfo;
  datasets: ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'];
  allDates: string[];
  onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void;
  onInitialize: (chart: Chart) => void;
  onPanComplete: (chart: Chart) => void;
  onZoomComplete: (chart: Chart) => void;
  devicePixelRatio?: number;
  disablePanZoom?: boolean;
  showTitle?: boolean;
  graphStyle?: CSSProperties;
  isExpanded: boolean;
  children?: (chart: Chart) => React.ReactNode;
  dataRange: DataRange;
  hideLegend?: boolean;
  isLegendInteractive?: boolean;
  xAxisAggregation?: 'monthly' | 'yearly';
}> = ({
  layerInfo,
  datasets,
  allDates,
  onClick,
  onInitialize,
  onPanComplete,
  onZoomComplete,
  devicePixelRatio,
  disablePanZoom,
  showTitle,
  graphStyle,
  isExpanded,
  children,
  dataRange,
  hideLegend = false,
  xAxisAggregation = 'monthly',
  // isLegendInteractive = true,
}) => {
  //TODO(eva): do we want fancy gradient colors based on the point value and layer? this might
  //be useful when viewing a single line, but less useful when viewing multiple lines.
  //if we do this, need to adapt pointColorCallback to use layer rather than graph i think

  /** Calculate x-axis values. */
  const {dates, timeRangeLimits, initialTimeRange} = React.useMemo(() => {
    const dates = allDates.map((date) => new Date(date));

    const firstDate = dates[0];
    const lastDate = dates[dates.length - 1];
    const differenceMs = lastDate.getTime() - firstDate.getTime();

    /** Buffer the time range limits on either end by 2.5% of the data range or
     * 2 days, whichever is larger, so data points and annotations at the edges
     * of the time range are fully visible. */
    const bufferMs = Math.max(differenceMs * 0.025, 2 * 24 * 60 * 60 * 1000);

    const timeRangeLimits: GraphTimeRange = [
      moment(firstDate).subtract(bufferMs, 'milliseconds').toDate(),
      moment(lastDate).add(bufferMs, 'milliseconds').toDate(),
    ];

    const initialTimeRange = timeRangeLimits;

    return {dates, timeRangeLimits, initialTimeRange};
  }, [allDates]);

  const legendPluginOptions = React.useMemo<PluginOptionsByType<'line'>['legend']>(
    () =>
      ({
        display: !hideLegend,
        labels: {
          generateLabels: (chart) => {
            return [...Chart.defaults.plugins.legend.labels.generateLabels(chart)];
          },
        },
      }) as PluginOptionsByType<'line'>['legend'],
    [hideLegend]
  );

  /** Allow chart to be optionally panned and zoomed. */
  const zoomPluginOptions = React.useMemo<PluginOptionsByType<'line'>['zoom']>(
    () => ({
      limits: {
        x: {
          min: timeRangeLimits[0].getTime(),
          max: timeRangeLimits[1].getTime(),
          minRange: 15 * 24 * 60 * 60 * 1000,
        },
      },
      pan: {
        mode: 'x',
        /** HACK: The chartjs-plugin-zoom plugin does not respond to changes in
         * the pan.enabled option after initialization, but it does respond to
         * changes in pan.onPanStart. */
        enabled: true,
        onPanStart: disablePanZoom ? () => false : undefined,
        onPanComplete: ({chart}) => onPanComplete(chart),
      },
      zoom: {
        mode: 'x',
        pinch: {
          enabled: !disablePanZoom,
        },
        wheel: {
          enabled: !disablePanZoom,
        },
        onZoomComplete: ({chart}) => onZoomComplete(chart),
      },
    }),
    [disablePanZoom, onPanComplete, onZoomComplete, timeRangeLimits]
  );

  /** Configure time scale (i.e., x-axis) settings. */
  const xScaleOptions = React.useMemo<ScaleOptionsByType<'time'>>(() => {
    return {
      type: 'time',
      min: initialTimeRange[0].getTime(),
      max: initialTimeRange[1].getTime(),
      time: {
        tooltipFormat: TOOLTIP_TIME_FORMAT,
        unit: xAxisAggregation === 'yearly' ? 'year' : 'month',
        displayFormats: {
          month: 'MMM YYYY',
          year: 'YYYY',
        },
      },
      ticks: {
        autoSkip: true,
        autoSkipPadding: 10,
        minRotation: 0,
        maxRotation: 0,
      },
      grid: {
        borderDash: [1, 1],
      },
    } as unknown as ScaleOptionsByType<'time'>;
  }, [initialTimeRange, xAxisAggregation]);

  /** Configure linear scale (i.e., y-axis) settings. */
  const yScaleOptions = React.useMemo<ScaleOptionsByType<'linear'>>(
    () =>
      ({
        type: 'linear',
        min: dataRange[0],
        max: dataRange[1] || 1, // If max is 0, set it to 1 to avoid breaking y-axis labels
        ticks: {
          precision: 1,
          maxTicksLimit: 2,
          callback: function (value, _index, _ticks) {
            return formatUnits(value, (layerInfo as DataLayerInfo).dataUnit);
          },
        },
        grid: {
          borderDash: [1, 1],
          drawBorder: false,
        },
      }) as ScaleOptionsByType<'linear'>,
    [dataRange, layerInfo]
  );
  const titlePluginOptions = React.useMemo<Partial<PluginOptionsByType<'line'>['title']>>(
    () => ({
      display: !!showTitle,
      text: layerInfo.display,
      font: {weight: 'normal'} as PluginOptionsByType<'line'>['title']['font'],
    }),
    [showTitle, layerInfo.display]
  );

  // const {renderAxisTooltip, hoverYAxisPlugin} = useYAxisTooltip(graph);

  const {chart, canvasRefCallback} = useLineChart({
    datasets,
    labels: dates,
    annotationPluginOptions: {
      annotations: [],
    },
    legendPluginOptions,
    zoomPluginOptions,
    titlePluginOptions,
    xScaleOptions,
    yScaleOptions,
    plugins: [],
    onClick,
    onInitialize,
    devicePixelRatio,
    isExpanded,
  });

  return (
    <React.Fragment>
      <div style={graphStyle}>
        <canvas ref={canvasRefCallback} />
      </div>
      {!!chart && !!children && children(chart)}
    </React.Fragment>
  );
};

export default MultiFeatureAnlaysisLineChart;
