import 'chartjs-adapter-moment';
import * as B from '@blueprintjs/core';
import React from 'react';
import {Column, useTable} from 'react-table';

import {ApiFeature} from 'app/modules/Remote/Feature';

import {FeatureStatsApiResponse} from '../hooks/useAggregatedFeatureStats';

type FeatureStatsRowData = {
  id: string;
  name: string;
  [date: string]: number | string;
};

/**
 * Component to render a table of feature rows, as well the setters to affect a chart that
 * takes each row as a dataset.
 */
const MultiFeatureAnlaysisTable: React.FunctionComponent<{
  displayFeatureIds: string[];
  allFeatureIds: string[];
  setDisplayFeatureIds: React.Dispatch<React.SetStateAction<string[]>>;
  featureStats: FeatureStatsApiResponse;
  //TODO: remove this once we have a way to get the feature name from the API response
  rawFeatures: ApiFeature[];
  allDates: string[];
  setShowAggregateTrend: React.Dispatch<React.SetStateAction<boolean>>;
  showAggregateTrend: boolean;
}> = ({
  displayFeatureIds,
  allFeatureIds,
  setDisplayFeatureIds,
  featureStats,
  allDates,
  rawFeatures,
  setShowAggregateTrend,
  showAggregateTrend,
}) => {
  const featureStatsRows = React.useMemo<FeatureStatsRowData[]>(
    () =>
      featureStats
        ? Object.entries(featureStats).map(([featureId, featureStat]) => ({
            ...featureStat,
            id: featureId,
            name:
              rawFeatures.find((f) => f.id.toString() === featureId)?.properties.name || featureId,
          }))
        : [],
    [featureStats, rawFeatures]
  );

  const columns = React.useMemo<Column<FeatureStatsRowData>[]>(
    () => [
      {
        Header: 'Property Name',
        accessor: (featureStat) => featureStat.id,
        Cell: ({row}) => {
          const featureStat = row.original;
          const featureId = featureStat.id.toString();
          const isSelected = displayFeatureIds.includes(featureId);
          const name = featureStat.name.toString();

          return (
            <div
              style={{
                cursor: 'pointer',
                padding: '8px',
                backgroundColor: isSelected ? '#e6f3ff' : 'transparent',
              }}
              onClick={() => {
                if (featureId) {
                  setDisplayFeatureIds((prev) => {
                    return prev.includes(featureId)
                      ? prev.filter((id) => id !== featureId)
                      : [...prev, featureId];
                  });
                }
              }}
              key={featureId}
            >
              {name}
            </div>
          );
        },
      },
      ...allDates.map((date) => ({
        Header: new Date(date).toLocaleDateString(),
        accessor: (feature) => {
          return feature[date];
        },
        Cell: ({cell}) => (
          <div style={{padding: '8px', textAlign: 'left'}}>
            {cell.value ? cell.value.toFixed(2) : '-'}
          </div>
        ),
      })),
    ],
    [displayFeatureIds, setDisplayFeatureIds, allDates]
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<FeatureStatsRowData>({
      columns,
      data: featureStatsRows,
    });

  return (
    <div>
      <B.AnchorButton
        text={showAggregateTrend ? 'Hide trend' : 'Show trend'}
        onClick={() => setShowAggregateTrend((prev) => !prev)}
      />
      <B.AnchorButton onClick={() => setDisplayFeatureIds(allFeatureIds)}>Show all</B.AnchorButton>

      <B.AnchorButton onClick={() => setDisplayFeatureIds([])}>Hide all</B.AnchorButton>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id} style={{textAlign: 'left'}}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={row.id}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MultiFeatureAnlaysisTable;
