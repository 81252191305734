import * as Sentry from '@sentry/react';
import * as I from 'immutable';
import React from 'react';

import api from 'app/modules/Remote/api';
import {ApiOrganization, ApiOverlay} from 'app/modules/Remote/Organization';
import {useProjects} from 'app/providers/ProjectsProvider';
import {recordEvent} from 'app/tools/Analytics';
import {useCachedApiGet} from 'app/utils/hookUtils';

import {LibraryDataset, LibraryDatasetType, OverlayDataset} from './LibraryTypes';
import {FEATURED_OVERLAY_METADATA} from './overlaysConfig';

export interface OverlaysActions {
  addOverlays: (projectIds: string[], featureCollectionId: number) => Promise<void>;
  removeOverlays: (projectIds: string[], featureCollectionId: number) => Promise<void>;
  deleteOverlay: (featureCollectionId: number) => Promise<void>;
  downloadOverlay: (featureCollectionId: number) => Promise<void>;
}

interface OverlaysContextValue {
  actions: OverlaysActions;
  //TODO(eva): make overlays not return immutable to make life easier in the two spots downstream
  overlays: I.ImmutableListOf<ApiOverlay>;
  upstreamOverlays: LibraryDataset<OverlayDataset>[];
}

export const OverlaysContext = React.createContext<OverlaysContextValue | undefined>(undefined);

export const OverlaysProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    organization: I.ImmutableOf<ApiOrganization>;
  }>
> = ({organization, children}) => {
  const organizationId = organization.get('id');

  const [projectsMap, , projectsActions] = useProjects();

  const [getOverlays, {invalidate, updateLocal}] = useCachedApiGet(async (_, orgId: string) => {
    const overlays = (await api.organizations.getOverlays(orgId)).get('data');
    return overlays;
  }, []);

  const overlays = getOverlays(organizationId).value?.toList() || I.List([]);

  const addOverlays = async (projectIds: string[], overlayFeatureCollectionId: number) => {
    try {
      recordEvent('Added dataset', {featureCollectionId: overlayFeatureCollectionId});
      // Optimistically update local data to show the new associations between projId and overlay
      updateLocal([organizationId], (prevOverlays) =>
        prevOverlays
          ?.map((overlay) =>
            overlay!.get('featureCollectionId') === overlayFeatureCollectionId
              ? overlay!.update('projectIds', (prevProjectIds) =>
                  prevProjectIds.concat(I.fromJS(projectIds)).toList()
                )
              : overlay!
          )
          .toList()
      );

      await Promise.all(
        projectIds.map((projId) => api.projects.addOverlay(projId, overlayFeatureCollectionId))
      );

      // Update the projects to include the new overlay without reloading the page
      if (projectsMap) {
        await Promise.all(
          projectIds.map((projId) => {
            const projectOverlays = projectsMap.toJS()[projId].featureCollections;
            projectOverlays.push({id: overlayFeatureCollectionId, kind: 'overlay'});
            projectsActions.updateProject(projId, {featureCollections: projectOverlays});
          })
        );
      }
    } catch (e) {
      // e is unknown, add a type, use optional chaining, and provide a default
      const errorBody = (e as {body: {error: string}; error: Error})?.body?.error || '';
      //if error, roll back local data to the server data
      /** NOTE: You could pass `true` as a second argument to the invalidate
       * function to clear out the local cache while the request is happening.
       * We’d probably only want to do this if we have a loading state. */
      invalidate([organizationId]);
      Sentry.captureException(e);
      // In lieu of actual error types or enums, check to see if this error is about being readonly
      const errorMessage = errorBody.includes('readonly')
        ? 'Please reach out to an admin or member on your team to add overlays.'
        : 'We weren’t able to add these overlays. Please try again.';
      window.alert(errorMessage);
    }
  };

  const removeOverlays = async (projectIds: string[], overlayFeatureCollectionId: number) => {
    try {
      // Optimistically update local data to remove associations between projId and overlay
      updateLocal([organizationId], (prevOverlays) =>
        prevOverlays
          ?.map((overlay) =>
            overlay!.get('featureCollectionId') === overlayFeatureCollectionId
              ? overlay!.update('projectIds', (prevProjectIds) =>
                  prevProjectIds
                    .filter((prevProjId) => !I.fromJS(projectIds).includes(prevProjId!))
                    .toList()
                )
              : overlay!
          )
          .toList()
      );
      await Promise.all(
        projectIds.map((projId) => api.projects.removeOverlay(projId, overlayFeatureCollectionId))
      );

      // Update the projects to remove the new overlay without reloading the page
      if (projectsMap) {
        await Promise.all(
          projectIds.map((projId) => {
            const projectOverlays = projectsMap.toJS()[projId].featureCollections;
            projectOverlays.pop({id: overlayFeatureCollectionId, kind: 'overlay'});
            projectsActions.updateProject(projId, {featureCollections: projectOverlays});
          })
        );
      }
    } catch (e) {
      // e is unknown, add a type, use optional chaining, and provide a default
      const errorBody = (e as {body: {error: string}; error: Error})?.body?.error || '';
      //if error, roll back local data to the server data
      invalidate([organizationId]);
      Sentry.captureException(e);
      // In lieu of actual error types or enums, check to see if this error is about being readonly
      const errorMessage = errorBody.includes('readonly')
        ? 'Please reach out to an admin or member on your team to remove overlays.'
        : 'We weren’t able to remove these overlays. Please try again.';
      window.alert(errorMessage);
    }
  };

  // this is a soft delete-- this will set is_archived = True for this feature collection; and the GET
  // overlays endpoint will filter out any overlays where is_archived == True.
  const deleteOverlay = async (featureCollectionId: number) => {
    try {
      // Optimistically update local data to remove our deleted overlay from the list of overlays
      updateLocal([organizationId], (prevOverlays) =>
        prevOverlays!.filter((o) => o!.get('featureCollectionId') !== featureCollectionId).toList()
      );
      await api.featureCollections.archiveFeatureCollection(featureCollectionId);
    } catch (e) {
      // e is unknown, add a type, use optional chaining, and provide a default
      const errorBody = (e as {body: {error: string}; error: Error})?.body?.error || '';
      //if error, roll back local data to the server data
      invalidate([organizationId]);
      Sentry.captureException(e);
      // In lieu of actual error types or enums, check to see if this error is about being readonly
      const errorMessage = errorBody.includes('readonly')
        ? 'Please reach out to an admin or member on your team to delete this overlay.'
        : 'We weren’t able to delete this overlay from your library. Please try again.';
      window.alert(errorMessage);
    }
  };

  const downloadOverlay = async (featureCollectionId: number) => {
    await api.featureCollections.export(featureCollectionId);
  };

  const actions: OverlaysActions = {
    addOverlays,
    removeOverlays,
    deleteOverlay,
    downloadOverlay,
  };

  const upstreamOverlays: LibraryDataset<OverlayDataset>[] = React.useMemo(() => {
    const filteredOverlays = overlays
      .filter((o) => o?.get('uploadedBy') === 'Upstream System')
      .toJS();

    return filteredOverlays.map((overlay) => ({
      ...overlay,
      source: FEATURED_OVERLAY_METADATA[overlay.name].source,
      geographicExtent: FEATURED_OVERLAY_METADATA[overlay.name].geographicExtent,
      description: FEATURED_OVERLAY_METADATA[overlay.name].description,
      restriction: FEATURED_OVERLAY_METADATA[overlay.name].restriction,
      tags: FEATURED_OVERLAY_METADATA[overlay.name].tags,
      license: FEATURED_OVERLAY_METADATA[overlay.name].license,
      libraryKey: FEATURED_OVERLAY_METADATA[overlay.name].libraryKey,
      type: LibraryDatasetType.OVERLAY,
    }));
  }, [overlays]);

  const value = React.useMemo(
    (): OverlaysContextValue => ({
      actions: actions,
      overlays: overlays,
      upstreamOverlays: upstreamOverlays,
    }),
    [actions, overlays, upstreamOverlays]
  );

  return <OverlaysContext.Provider value={value}>{children}</OverlaysContext.Provider>;
};

export function useOverlays(): OverlaysContextValue {
  const value = React.useContext(OverlaysContext);

  if (!value) {
    throw new Error('useOverlays must be beneath an OverlaysProvider');
  }

  return value;
}

export const FakeOverlaysProvider: React.FunctionComponent<
  React.PropsWithChildren<{
    actions: OverlaysActions;
    overlays: I.ImmutableListOf<ApiOverlay>;
    upstreamOverlays: LibraryDataset<OverlayDataset>[];
  }>
> = ({children, actions, overlays, upstreamOverlays}) => {
  const value = React.useMemo(
    (): OverlaysContextValue => ({
      actions: actions,
      overlays: overlays,
      upstreamOverlays: upstreamOverlays,
    }),
    [actions, overlays, upstreamOverlays]
  );

  return <OverlaysContext.Provider value={value}>{children}</OverlaysContext.Provider>;
};

export default OverlaysProvider;
