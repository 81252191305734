import * as B from '@blueprintjs/core';
import * as I from 'immutable';
import React from 'react';

import {DashboardWidget} from 'app/components/Dashboard/DashboardWidget';
import {
  FeatureStatsApiResponse,
  MultifeatureAnalysisConfig,
  useMultiFeatureAnalysisStats,
} from 'app/components/Dashboard/hooks/useAggregatedFeatureStats';
import {
  getAllTimestamps,
  makeAverageFeatureDatasets,
  makeMultiFeatureDatasets,
} from 'app/components/Dashboard/utils/chartUtils';
import {ApiFeature} from 'app/modules/Remote/Feature';
import {DataLayerInfo} from 'app/utils/layers';
import {getLayer} from 'app/utils/layerUtils';

import * as cs from './MultiFeatureAnalysis.styl';
import MultiFeatureAnlaysisLineChart from './MultiFeatureAnalysisChart';
import MultiFeatureAnlaysisTable from './MultiFeatureAnalysisTable';

export const MultiFeatureAnalysisWidget: React.FC<{
  features: I.ImmutableOf<ApiFeature[]>; // TODO: get rid of this prop
  widgetConfig: MultifeatureAnalysisConfig;
}> = ({features, widgetConfig}) => {
  const rawFeatures = React.useMemo(() => features.toJS() as ApiFeature[], [features]);

  //TODO: This is where we will control the current set of currently displayed datasets.
  //We will need to pass the setter logic here to both the chart and the table, so that
  //we can affect the chart by interacting with the table (and vice versa?)
  const [showAggregateTrend, setShowAggregateTrend] = React.useState(true);
  const [displayFeatureIds, setDisplayFeatureIds] = React.useState<string[]>([]);
  const [showFeatureTable, setShowFeatureTable] = React.useState(false);

  const {data, isLoading, error, isFetching} = useMultiFeatureAnalysisStats(widgetConfig);

  const [multiFeatureDatasets, setMultiFeatureDatasets] = React.useState(
    data ? makeMultiFeatureDatasets(data, rawFeatures) : null
  );

  React.useEffect(() => {
    if (displayFeatureIds) {
      const filteredData: FeatureStatsApiResponse = {};
      for (const featureId in data) {
        if (displayFeatureIds.includes(featureId)) {
          filteredData[featureId] = data[featureId];
        }
      }
      const newDatasets = makeMultiFeatureDatasets(filteredData, rawFeatures);
      setMultiFeatureDatasets(newDatasets);
    }
  }, [data, displayFeatureIds, rawFeatures]);

  // TODO: the type of aggregation we do here will depend on the dataset. for category
  // we'll want to sum area of each category, rather than means.
  const topLevelTrendDatasets = React.useMemo(
    () => (data ? makeAverageFeatureDatasets(data) : null),
    [data]
  );

  const {featureIds, layerKey, timeAggregation} = widgetConfig;
  const layer = getLayer(layerKey);
  const allDates = data ? getAllTimestamps(data) : [];

  return (
    <DashboardWidget
      title={widgetConfig.name}
      size={showFeatureTable ? 'large' : 'small'}
      controls={
        <div>
          <B.Tooltip content={showFeatureTable ? 'Hide details' : 'Show details'}>
            <B.AnchorButton
              icon={showFeatureTable ? 'minimize' : 'maximize'}
              minimal
              small
              onClick={() => {
                setShowFeatureTable((prev) => !prev);
                setDisplayFeatureIds(showFeatureTable ? [] : featureIds);
              }}
            />
          </B.Tooltip>
          {/* TODO - placeholder for menu of actions (download, etc) */}
          <B.AnchorButton icon="more" minimal small />
        </div>
      }
    >
      {/* Basic loading/error display */}
      {isLoading && <div>Loading...</div>}
      {isFetching && <div>Fetching...</div>}
      {error && <div>Error: {error.message}</div>}

      <div>
        <p>
          <span className={cs.widgetLabel}>Description:</span> {widgetConfig.description}
        </p>
        {/* Summary of data displayed in the chart */}
        <p>
          <span className={cs.widgetLabel}>Chart: </span>{' '}
          {`${layer.display} | ${widgetConfig.featureIds.length} properties`}
        </p>
      </div>

      {multiFeatureDatasets && featureIds && (
        <MultiFeatureAnlaysisLineChart
          allDates={allDates}
          onClick={() => null}
          layerInfo={layer as DataLayerInfo}
          datasets={
            showAggregateTrend && !!topLevelTrendDatasets
              ? [...topLevelTrendDatasets, ...multiFeatureDatasets]
              : multiFeatureDatasets
          }
          onInitialize={() => null}
          onPanComplete={() => null}
          onZoomComplete={() => null}
          isExpanded={false}
          dataRange={(layer as DataLayerInfo).dataRange}
          xAxisAggregation={timeAggregation}
          hideLegend
          graphStyle={{flex: 1}}
        />
      )}

      {featureIds && showFeatureTable && data && (
        <MultiFeatureAnlaysisTable
          displayFeatureIds={displayFeatureIds}
          allFeatureIds={featureIds}
          setDisplayFeatureIds={setDisplayFeatureIds}
          featureStats={data}
          rawFeatures={rawFeatures}
          allDates={allDates}
          setShowAggregateTrend={setShowAggregateTrend}
          showAggregateTrend={showAggregateTrend}
        />
      )}
    </DashboardWidget>
  );
};
