import {UseQueryResult, useQuery} from '@tanstack/react-query';

import {MOCK_RESPONSE} from '../mockData';

export interface MultifeatureAnalysisConfig {
  featureIds: string[];
  startDate: string;
  endDate: string;
  statType: 'mean' | 'sum';
  layerKey: string;
  timeAggregation: 'monthly' | 'yearly';
  name: string;
  description: string;
}

// Example: { "feature1": { "2023-01-01T00:00:00Z": 10.5, "2023-02-01T00:00:00Z": 11.2 }, ... }
export type FeatureStatsApiResponse = {[featureId: string]: {[date: string]: number}};

// (Replace with actual implementation)
async function fetchFeatureStatsFromApi(
  params: Omit<MultifeatureAnalysisConfig, 'name' | 'description'>
): Promise<FeatureStatsApiResponse> {
  console.info('fetchFeatureStatsFromApi', params);
  return MOCK_RESPONSE;
}

export function useMultiFeatureAnalysisStats({
  featureIds,
  layerKey,
  startDate,
  endDate,
  statType = 'mean',
  timeAggregation = 'monthly',
}: MultifeatureAnalysisConfig): UseQueryResult<FeatureStatsApiResponse, Error> {
  // Stable query key including all relevant API parameters
  const queryKey = [
    'aggregatedFeatureStats', // New key base reflects the hook's purpose
    featureIds?.sort().join('-') || 'no-features',
    layerKey,
    startDate,
    endDate,
    statType,
    timeAggregation,
  ];

  return useQuery({
    queryKey,
    queryFn: async (): Promise<FeatureStatsApiResponse> => {
      try {
        const apiResponse = await fetchFeatureStatsFromApi({
          featureIds,
          startDate,
          endDate,
          statType,
          layerKey,
          timeAggregation,
        });

        return apiResponse;
      } catch (error) {
        console.error('Error during aggregated stats processing:', error);
        if (error instanceof Error) {
          throw error;
        } else {
          throw new Error(String(error || 'Unknown processing error'));
        }
      }
    },
  });
}
